import React, { useState, useEffect } from "react";
import EmptyState from "components/Common/EmptyState";
import { Edit, Delete } from "@bigbinary/neeto-icons";
import { Header, Container } from "@bigbinary/neetoui/layouts";
import {
  Button,
  Spinner,
  Alert,
  Modal,
  Pane,
  Typography,
} from "@bigbinary/neetoui";
import Pluralize from "pluralize";
import {
  getArtists,
  getArtist,
  createArtist,
  updateArtist,
  destroyArtist,
} from "apis/artists/artists";
import { useFormik } from "formik";
import { showToastrError } from "common";
import { useParams, useHistory } from "react-router-dom";
import { getRandomNotFoundImage, artistModuleName } from "common/helper";
import { DETAILS_INITIAL_VALUE, EDIT_VALIDATION_SCHEMA } from "./constants";
import ArtistDirectory from "./ArtistDirectory";
import ArtistDetails from "./ArtistDetails";
import CurrentArtistListModal from "./CurrentArtistListModal";
import EditArtistForm from "./EditArtistForm";

const Artists = () => {
  const { id } = useParams();
  const history = useHistory();
  const [currentArtistModalState, setCurrentArtistModalState] = useState(false);
  const [artistList, setArtistList] = useState([]);
  const [artistDetail, setArtistDetail] = useState({});
  const [editPane, setEditPane] = useState(false);
  const [artistListLoading, setArtistListLoading] = useState(true);
  const [artistDetailLoading, setArtistDetailLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [editingArtistId, setEditingArtistId] = useState("");
  const [btnLoad, setBtnLoad] = useState(false);
  const [list, setList] = useState([]);
  const [emptyImage, setEmptyImage] = useState();

  const artistModule = artistModuleName();

  const editFormik = useFormik({
    enableReinitialize: true,
    initialValues:
      artistDetail && editingArtistId
        ? {
            ...artistDetail,
            artistUrl: artistDetail?.artistUrl?.replace(/(^\w+:|^)\/\//, ""),
            personalWebsite: artistDetail?.personalWebsite?.replace(
              /(^\w+:|^)\/\//,
              ""
            ),
            about: artistDetail?.about?.split("\\n").join("\n"),
            tags: artistDetail?.territoriesRepresentedIn,
          }
        : DETAILS_INITIAL_VALUE,
    validationSchema: EDIT_VALIDATION_SCHEMA,
    onSubmit: () => {
      if (editFormik.values.id) {
        onUpdate();
      } else {
        onCreate();
      }
    },
  });

  useEffect(() => {
    loadArtistListResponse();
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  useEffect(() => {
    if (id) {
      loadArtistDetailResponse(id);
    } else {
      setArtistDetail();
    }
  }, [id]);

  const loadArtistListResponse = async (searchParams = "", page = 1) => {
    try {
      const response = await getArtists(searchParams, page);
      setArtistList(response.data.artists);
      setTotalRecords(response.data.totalRecords);

      if (!id && response.data.artists.length > 0) {
        let path = `/${artistModule.toLowerCase()}/${
          response.data.artists[0].id
        }`;
        history.push(path);
      }

      setArtistListLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadArtistDetailResponse = async artistId => {
    try {
      setArtistDetailLoading(true);
      const response = await getArtist(artistId);
      setArtistDetail(response.data.artist);
      setArtistDetailLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const onCreate = async () => {
    try {
      setBtnLoad(true);
      const {
        streetAddress,
        suburb,
        state,
        country,
        postcode,
        artistUrl,
        personalWebsite,
        addressId,
        tags,
      } = editFormik.values;
      let addressPayload = {
        artistUrl: artistUrl ? `https://${artistUrl}` : "",
        personalWebsite: personalWebsite ? `https://${personalWebsite}` : "",
        address_attributes: {
          id: addressId,
          street_address: streetAddress,
          suburb: suburb,
          state: state,
          country: country,
          postcode: postcode,
        },
      };
      const values = {
        ...editFormik.values,
        dateOfBirth: editFormik.values.dateOfBirth,
      };
      if (editFormik.values.company) {
        delete values.firstName;
        delete values.lastName;
        delete values.email;
      }
      let payload = { artist: { ...values, ...addressPayload } };
      const response = await createArtist(payload);
      loadArtistDetailResponse(response.data.id);
      setEditPane(false);
      setEditingArtistId();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setBtnLoad(false);
    }
  };

  const onUpdate = async () => {
    try {
      setBtnLoad(true);
      const {
        streetAddress,
        suburb,
        state,
        country,
        postcode,
        artistUrl,
        personalWebsite,
        addressId,
        tags,
      } = editFormik.values;
      let addressPayload = {
        artistUrl: artistUrl ? `https://${artistUrl}` : "",
        personalWebsite: personalWebsite ? `https://${personalWebsite}` : "",
        address_attributes: {
          id: addressId,
          street_address: streetAddress,
          suburb: suburb,
          state: state,
          country: country,
          postcode: postcode,
        },
      };
      delete editFormik.values["artistId"];
      let payload = {
        artist: {
          ...editFormik.values,
          ...addressPayload,
          dateOfBirth: editFormik.values.dateOfBirth,
        },
      };
      await updateArtist(artistDetail.id, payload);
      loadArtistDetailResponse(artistDetail.id);
      setList([]);
      loadArtistListResponse();
      setEditPane(false);
      setEditingArtistId();
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setBtnLoad(false);
    }
  };

  const onDelete = async artistId => {
    try {
      setBtnLoad(true);
      await destroyArtist(artistId);
      setList([]);
      setArtistDetail({});
      loadArtistListResponse();
      setEditingArtistId();
      history.push("/artists");
    } catch (error) {
      showToastrError(error.data.errors[0]);
    } finally {
      setBtnLoad(false);
    }
  };

  if (artistListLoading) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <Container isHeaderFixed>
      <Header
        title={artistModule}
        actionBlock={
          <div className="flex space-x-3">
            {artistDetail?.id && (
              <>
                <Button
                  label="Delete"
                  style="danger-text"
                  icon={Delete}
                  iconPosition="left"
                  onClick={async () => {
                    await setEditingArtistId(artistDetail?.id);
                    setDeleteAlertOpen(true);
                  }}
                />
                <Button
                  style="secondary"
                  label="Edit"
                  icon={Edit}
                  iconPosition="left"
                  onClick={() => {
                    setEditPane(true);
                    loadArtistDetailResponse(artistDetail?.id);
                    setEditingArtistId(artistDetail?.id);
                  }}
                />
              </>
            )}
            <Button
              style="secondary"
              label={`Current ${artistModule}`}
              onClick={() => setCurrentArtistModalState(true)}
            />
            <Button
              label={`Add ${artistModule}`}
              onClick={async () => {
                await editFormik.resetForm();
                setEditPane(true);
                setEditingArtistId();
              }}
            />
          </div>
        }
      />

      <div className="flex w-full h-screen overflow-hidden border-t border-gray-200">
        {!artistDetailLoading && !artistDetail?.id ? (
          <EmptyState
            image={emptyImage}
            title={`Please click on any ${artistModule.toLowerCase()} to access the details.`}
          />
        ) : artistDetailLoading ? (
          <div className="flex items-center justify-center w-full">
            <Spinner />
          </div>
        ) : (
          <ArtistDetails artistDetail={artistDetail} />
        )}
        <ArtistDirectory
          artistList={artistList}
          loadArtistListResponse={loadArtistListResponse}
          artistDetail={artistDetail}
          totalRecords={totalRecords}
          list={list}
          setList={setList}
        />
      </div>

      <Modal
        isOpen={currentArtistModalState}
        onClose={() => setCurrentArtistModalState(false)}
        className="w-screen h-screen rounded-none"
      >
        <Modal.Header>
          <Typography style="h2" weight="semibold">
            {`Current ${artistModule}`}
          </Typography>
        </Modal.Header>
        <Modal.Body>
          <CurrentArtistListModal
            setCurrentArtistModalState={setCurrentArtistModalState}
          />
        </Modal.Body>
      </Modal>

      <Pane isOpen={editPane} onClose={() => setEditPane(false)}>
        <Pane.Header>
          <Typography style="h2" weight="semibold">
            {editFormik.values.id
              ? `Edit ${artistModule}`
              : `Add ${artistModule}`}
          </Typography>
        </Pane.Header>
        <Pane.Body className="px-0">
          <EditArtistForm formik={editFormik} />
        </Pane.Body>
        <Pane.Footer className="space-x-2">
          <Button
            label="Save Changes"
            onClick={() => editFormik.handleSubmit()}
            loading={btnLoad}
          />
          <Button
            style="text"
            label="Cancel"
            onClick={() => setEditPane(false)}
          />
        </Pane.Footer>
      </Pane>

      <Alert
        isOpen={deleteAlertOpen}
        title={`Delete ${Pluralize(artistModule, 1)}`}
        message={`Are you sure you want to delete this ${Pluralize(
          artistModule.toLowerCase(),
          1
        )}?`}
        onClose={() => setDeleteAlertOpen(false)}
        onSubmit={() => {
          onDelete(editingArtistId);
          setDeleteAlertOpen(false);
          setEditPane(false);
        }}
        isSubmitting={btnLoad}
        submitButtonLabel="Yes, delete"
        cancelButtonLabel="No, cancel"
      />
    </Container>
  );
};

export default Artists;
