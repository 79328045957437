import React, { useState, useEffect } from "react";
import { User } from "@bigbinary/neeto-icons";
import { Label, Button, Typography, Tag } from "@bigbinary/neetoui";
import { artistModuleName, getRandomNotFoundImage } from "common/helper";
import { parsePhoneNumber, ParseError } from "libphonenumber-js";
import Pluralize from "pluralize";
import { countries } from "countries-list";
import EmptyState from "components/Common/EmptyState";

const ArtistProfile = ({ artistDetail, coverImage, heroImage }) => {
  const [emptyImage, setEmptyImage] = useState();

  useEffect(() => {
    setEmptyImage(getRandomNotFoundImage());
  }, []);

  const phoneNumberParsed = () => {
    try {
      if (parsePhoneNumber(`+${artistDetail.phoneNumber}`)) {
        return parsePhoneNumber(
          `+${artistDetail.phoneNumber}`
        ).formatInternational();
      }
    } catch (error) {
      if (error instanceof ParseError) {
        let countryWithMatchName =
          Object.entries(countries).find(item => {
            return (
              item[0].toLowerCase() === artistDetail.country?.toLowerCase() ||
              item[1].name.toLowerCase() === artistDetail.country?.toLowerCase()
            );
          }) || "AU";

        try {
          return parsePhoneNumber(
            `${artistDetail.phoneNumber}`,
            countryWithMatchName[0]
          ).formatInternational();
        } catch (error) {
          if (error instanceof ParseError) {
            // Code
          }
        }
      }
    }
  };

  if (!artistDetail?.id) {
    return (
      <div
        className="flex items-center justify-center p-2"
        style={{ height: "calc(100vh - 134px)" }}
      >
        <EmptyState
          image={emptyImage}
          title={`Please click on any ${artistModuleName()} to access the details.`}
        />
      </div>
    );
  }

  return (
    <div
      className="flex flex-col overflow-y-auto"
      style={{ height: "calc(100vh - 134px)" }}
    >
      <div className="flex flex-col">
        <div
          className="object-cover w-full h-32 bg-gray-100 bg-cover border-0 border-b border-gray-200 lg:h-48"
          style={{
            background: `url(${coverImage?.src})`,
          }}
        ></div>
        <div className="max-w-5xl px-6">
          <div className="-mt-16">
            <div
              className="flex w-32 h-32 overflow-hidden bg-white bg-cover border border-gray-200 rounded-full shadow-xs ring-4 ring-white"
              style={{
                background: `url(${heroImage?.thumbSrc})`,
              }}
            >
              {!heroImage && (
                <div className="flex items-center justify-center w-full h-full bg-gray-100">
                  <User size={54} className="text-gray-500" />
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-1 min-w-0 mt-6 2xl:hidden">
            <Typography style="h3" weight="bold">
              {`${artistDetail?.name} ${
                artistDetail.pronouns ? `(${artistDetail.pronouns})` : ""
              }`}
            </Typography>
          </div>
        </div>
      </div>
      <div className="flex flex-col px-6 my-6">
        <div className="grid grid-cols-3 gap-x-4 gap-y-6">
          <div className="flex flex-col space-y-1">
            <Label>{Pluralize(artistModuleName(), 1)} ID</Label>
            <p className="text-sm font-semibold text-gray-800">
              {artistDetail.serialNumber || "N/A"}
            </p>
          </div>

          {!artistDetail.company && (
            <>
              <div className="flex flex-col space-y-1">
                <Label>Email</Label>
                <p className="text-sm font-semibold text-gray-800">
                  {artistDetail.email || "N/A"}
                </p>
              </div>
              <div className="flex flex-col space-y-1">
                <Label>Phone</Label>
                <p className="text-sm font-semibold text-gray-800">
                  {artistDetail.phoneNumber
                    ? phoneNumberParsed(`${artistDetail.phoneNumber}`)
                    : "N/A"}
                </p>
              </div>
            </>
          )}

          <div className="flex flex-col space-y-1">
            <Label>Salutation</Label>
            <p className="text-sm font-semibold text-gray-800">
              {artistDetail.salutation || "N/A"}
            </p>
          </div>

          {artistDetail.company && (
            <>
              <div className="flex flex-col space-y-1">
                <Label>Company Email</Label>
                <p className="text-sm font-semibold text-gray-800">
                  {artistDetail.companyEmail || "N/A"}
                </p>
              </div>

              <div className="flex flex-col space-y-1">
                <Label>Company Phone</Label>
                <p className="text-sm font-semibold text-gray-800">
                  {artistDetail.companyPhone || "N/A"}
                </p>
              </div>
            </>
          )}

          {!artistDetail.company && (
            <div className="flex flex-col space-y-1">
              <Label>DOB</Label>
              <p className="text-sm font-semibold text-gray-800">
                {artistDetail.dateOfBirth || "N/A"}
              </p>
            </div>
          )}

          <div className="flex flex-col space-y-1">
            <Label>Street Address</Label>
            <p className="text-sm font-semibold text-gray-800">
              {artistDetail?.streetAddress
                ? artistDetail?.streetAddress?.split("\n").join("<br/>")
                : "N/A"}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>City/Suburb</Label>
            <p className="text-sm font-semibold text-gray-800">
              {artistDetail.suburb || artistDetail.city || "N/A"}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>State</Label>
            <p className="text-sm font-semibold text-gray-800">
              {artistDetail.state || "N/A"}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Country</Label>
            <p className="text-sm font-semibold text-gray-800">
              {artistDetail.country || "N/A"}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Postcode</Label>
            <p className="text-sm font-semibold text-gray-800">
              {artistDetail.postcode || "N/A"}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Tax ID</Label>
            <p className="text-sm font-semibold text-gray-800">
              {artistDetail.abn || "N/A"}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Tax Status</Label>
            <p className="text-sm font-semibold text-gray-800">
              {artistDetail.taxType.name ? artistDetail.taxType.name : "N/A"}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Name of Account</Label>
            <p className="text-sm font-semibold text-gray-800">
              {artistDetail?.accountName}
            </p>
          </div>

          <div className="flex flex-col col-span-2 space-y-1">
            <Label>Account Number</Label>
            <p
              className="text-sm font-semibold text-gray-800"
              dangerouslySetInnerHTML={{
                __html: artistDetail?.accountNumber
                  ? artistDetail?.accountNumber?.split("\n").join("<br>")
                  : "N/A",
              }}
            />
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Agency Group</Label>
            <p className="text-sm font-semibold text-gray-800">
              {artistDetail.agencyGroupNames || "N/A"}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Assigned Producer</Label>
            <p className="text-sm font-semibold text-gray-800">
              {artistDetail.userFullName || "N/A"}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Currently Represented</Label>
            <p>
              {artistDetail.currentlyRepresented ? (
                <Tag label="Yes" style="success" type="solid" />
              ) : (
                <Tag label="No" style="danger" type="solid" />
              )}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Territories Represented In</Label>
            <p className="text-sm font-semibold text-gray-800">
              {artistDetail.territoriesRepresentedIn}
            </p>
          </div>

          <div className="flex flex-col space-y-1">
            <Label>Additional Reps</Label>
            <p className="text-sm font-semibold text-gray-800">
              {artistDetail.additionalReps}
            </p>
          </div>

          <div className="flex flex-col col-span-1 space-y-1">
            <Label>Agency URL</Label>
            {artistDetail.artistUrl ? (
              <Button
                style="link"
                href={artistDetail.artistUrl}
                target="_blank"
                className="text-sm font-semibold"
                label={artistDetail.artistUrl}
              />
            ) : (
              <p className="text-sm font-semibold text-gray-800">N/A</p>
            )}
          </div>

          <div className="flex flex-col col-span-1 space-y-1">
            <Label>RCI Company Name</Label>
            <p className="text-sm font-semibold text-gray-800">
              {artistDetail.rciCompanyName || "N/A"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtistProfile;
