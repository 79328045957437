import React, { useState, useEffect } from "react";
import CountryPhone from "components/Common/CountryPhone";
import CountrySelect from "components/Common/CountrySelect";

import { Input, Textarea, Select, Switch, Spinner } from "@bigbinary/neetoui";
import { countries } from "countries-list";
import { getStaffs } from "apis/artists/staffs";
import { getAgencyGroups } from "apis/settings/agency_groups";
import { showToastrError } from "common";
import { dropDownListGenerator } from "common/helper";
import TagInput from "components/Common/TagInput";

const EditArtistDetails = ({ formik }) => {
  const [company, setCompany] = useState(formik.values.company);
  const [country, setCountry] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [staffOptions, setStaffOptions] = useState([]);
  const [staffListLoading, setStaffListLoading] = useState([]);
  const [agencyGroupList, setAgencyGroupList] = useState([]);
  const [agencyGroupListLoading, setAgencyGroupListLoading] = useState(true);

  useEffect(() => {
    loadStaffListResponse();
    loadAgencyGroupList();
  }, []);

  useEffect(() => {
    if (formik.values.country) {
      let countryName = formik.values.country;

      let countryObj = Object.entries(countries).find(item => {
        return (
          item[0].toLowerCase() === countryName?.toLowerCase() ||
          item[1].name.toLowerCase() === countryName?.toLowerCase()
        );
      });

      if (countryObj) {
        setCountry(countryObj[0]);
        setSelectedCountry(countryObj[1]);
      }
    }
  }, []);

  useEffect(() => {
    if (country?.value) {
      formik.setFieldValue("country", country.value);
      setSelectedCountry(country);
    } else if (country === null) {
      formik.setFieldValue("country", "");
      setSelectedCountry();
    }
  }, [country]);

  const loadStaffListResponse = async () => {
    try {
      const response = await getStaffs();
      setStaffOptions(dropDownListGenerator(response.data.staffs));
      setStaffListLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const loadAgencyGroupList = async () => {
    try {
      const agencyGroupListResponse = await getAgencyGroups();
      setAgencyGroupList(agencyGroupListResponse.data.agencyGroups || []);
      setAgencyGroupListLoading(false);
    } catch (error) {
      showToastrError(error.data.errors[0]);
    }
  };

  const setCompanyValue = value => {
    setCompany(value);
    formik.setFieldValue("company", value);
  };

  if (staffListLoading || agencyGroupListLoading) {
    return (
      <div
        className="flex items-center justify-center w-full"
        style={{ height: "calc(100vh - 208px)" }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className="px-6 pt-6 pb-12 space-y-6 overflow-y-auto"
      style={{ height: "calc(100vh - 208px)" }}
    >
      <Switch
        label="Company"
        checked={company}
        onChange={() => setCompanyValue(!company)}
      />

      {company && (
        <>
          <Input
            label="Company Name"
            required={company}
            name="companyName"
            id="companyName"
            onChange={e => {
              formik.setFieldValue("companyName", e.target.value);
              formik.setFieldValue("salutation", e.target.value);
            }}
            value={formik.values.companyName}
            error={
              Boolean(
                formik.touched.companyName && formik.errors.companyName
              ) && formik.errors.companyName
            }
          />

          <Input
            required={company}
            label="Company Email"
            name="companyEmail"
            onChange={formik.handleChange}
            value={formik.values.companyEmail}
            error={
              Boolean(
                formik.touched.companyEmail && formik.errors.companyEmail
              ) && formik.errors.companyEmail
            }
            {...formik.getFieldProps("companyEmail")}
          />
        </>
      )}

      {!company && (
        <>
          <Input
            required={true}
            label="First Name"
            name="firstName"
            onChange={e => {
              formik.setFieldValue("firstName", e.target.value);
              formik.setFieldValue("salutation", e.target.value);
            }}
            value={formik.values.firstName}
            error={
              Boolean(formik.touched.firstName && formik.errors.firstName) &&
              formik.errors.firstName
            }
          />

          <Input
            required={true}
            label="Last Name"
            name="lastName"
            onChange={formik.handleChange}
            value={formik.values.lastName}
            error={
              Boolean(formik.touched.lastName && formik.errors.lastName) &&
              formik.errors.lastName
            }
            {...formik.getFieldProps("lastName")}
          />
          <Input
            label="Pronouns"
            name="pronouns"
            onChange={formik.handleChange}
            value={formik.values.pronouns}
            error={
              Boolean(formik.touched.pronouns && formik.errors.pronouns) &&
              formik.errors.pronouns
            }
            {...formik.getFieldProps("pronouns")}
          />
          <Input
            label="Email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            required={true}
            error={
              Boolean(formik.touched.email && formik.errors.email) &&
              formik.errors.email
            }
            {...formik.getFieldProps("email")}
          />

          <Input
            label="Date of Birth"
            name="dateOfBirth"
            onChange={formik.handleChange}
            value={formik.values.dateOfBirth}
            error={
              Boolean(
                formik.touched.dateOfBirth && formik.errors.dateOfBirth
              ) && formik.errors.dateOfBirth
            }
            {...formik.getFieldProps("dateOfBirth")}
          />
        </>
      )}

      <Textarea
        label="Street Address"
        name="streetAddress"
        onChange={formik.handleChange}
        value={formik.values.streetAddress}
        error={
          Boolean(
            formik.touched.streetAddress && formik.errors.streetAddress
          ) && formik.errors.streetAddress
        }
        {...formik.getFieldProps("streetAddress")}
      />

      <Input
        label="City/Suburb"
        name="suburb"
        onChange={formik.handleChange}
        value={formik.values.suburb}
        error={
          Boolean(formik.touched.suburb && formik.errors.suburb) &&
          formik.errors.suburb
        }
        {...formik.getFieldProps("suburb")}
      />

      <Input
        label="State"
        name="state"
        onChange={formik.handleChange}
        value={formik.values.state}
        error={
          Boolean(formik.touched.state && formik.errors.state) &&
          formik.errors.state
        }
        {...formik.getFieldProps("state")}
      />

      <Input
        label="Postcode"
        name="postcode"
        onChange={formik.handleChange}
        value={formik.values.postcode}
        error={
          Boolean(formik.touched.postcode && formik.errors.postcode) &&
          formik.errors.postcode
        }
        {...formik.getFieldProps("postcode")}
      />

      <CountrySelect
        label="Country"
        onCountryChange={setCountry}
        country={country}
        showCountryName={true}
        showCloseButton={true}
      />

      <Input
        name="salutation"
        id="salutation"
        label="Salutation"
        helpText="Salutation is used in emails."
        onChange={formik.handleChange}
        value={formik.values.salutation}
        error={
          Boolean(formik.touched.salutation && formik.errors.salutation) &&
          formik.errors.salutation
        }
        {...formik.getFieldProps("salutation")}
      />

      {!company && (
        <CountryPhone
          formik={formik}
          required={false}
          selectedCountry={selectedCountry}
        />
      )}

      {company && (
        <CountryPhone
          formik={formik}
          required={false}
          selectedCountry={selectedCountry}
          attribute="companyPhone"
        />
      )}

      <Textarea
        label="Personal Details"
        name="about"
        value={formik.values.about}
        onChange={formik.handleChange}
        error={
          Boolean(formik.touched.about && formik.errors.about) &&
          formik.errors.about
        }
        row="4"
        {...formik.getFieldProps("about")}
      />

      <Textarea
        label="Other Notes"
        name="otherNotes"
        value={formik.values.otherNotes}
        onChange={formik.handleChange}
        error={
          Boolean(formik.touched.otherNotes && formik.errors.otherNotes) &&
          formik.errors.otherNotes
        }
        row="4"
        {...formik.getFieldProps("otherNotes")}
      />

      <Input
        label="Emergency Contact"
        name="emergencyContact"
        onChange={formik.handleChange}
        value={formik.values.emergencyContact}
        error={
          Boolean(
            formik.touched.emergencyContact && formik.errors.emergencyContact
          ) && formik.errors.emergencyContact
        }
        {...formik.getFieldProps("emergencyContact")}
      />

      <Switch
        label="Currently Represented"
        checked={formik.values.currentlyRepresented}
        onChange={() => {
          formik.setFieldValue(
            "currentlyRepresented",
            !formik.values.currentlyRepresented
          );
        }}
      />

      <Switch
        label="Vendor"
        checked={formik.values.vendor}
        onChange={() => {
          formik.setFieldValue("vendor", !formik.values.vendor);
        }}
      />

      <Select
        isClearable
        label="Assigned Producer"
        name="userId"
        id="userId"
        placeholder="Select an Option"
        value={
          staffOptions.find(staff => staff.value === formik.values.userId) || ""
        }
        onBlur={() => formik.setFieldTouched("userId", true)}
        error={
          Boolean(formik.touched.userId && formik.errors.userId) &&
          formik.errors.userId
        }
        touched={formik.touched.userId}
        options={staffOptions}
        onChange={opt => {
          if (opt) {
            formik.setFieldValue("userId", opt.value);
          } else {
            formik.setFieldValue("userId", "");
          }
        }}
      />

      <Select
        isMulti
        label="Agency Group"
        name="agencyGroupIds"
        id="agencyGroupIds"
        placeholder="Select agency group"
        value={dropDownListGenerator(agencyGroupList).filter(agencyGroup =>
          formik.values.agencyGroupIds?.includes(agencyGroup.value)
        )}
        error={
          Boolean(
            formik.touched.agencyGroupIds && formik.errors.agencyGroupIds
          ) && formik.errors.agencyGroupIds
        }
        options={dropDownListGenerator(agencyGroupList)}
        onChange={option => {
          if (option) {
            formik.setFieldValue(
              "agencyGroupIds",
              option?.map(op => op?.value)
            );
          } else {
            formik.setFieldValue("agencyGroupIds", []);
          }

          formik.setFieldTouched("agencyGroupIds", true);
        }}
      />

      <Textarea
        label="Territories Represented In"
        name="territoriesRepresentedIn"
        value={formik.values.territoriesRepresentedIn}
        onChange={e =>
          formik.setFieldValue("territoriesRepresentedIn", e.target.value)
        }
        error={
          Boolean(
            formik.touched.territoriesRepresentedIn &&
              formik.errors.territoriesRepresentedIn
          ) && formik.errors.territoriesRepresentedIn
        }
        row="4"
      />

      <Textarea
        label="Additional Reps"
        name="additionalReps"
        value={formik.values.additionalReps}
        onChange={e => formik.setFieldValue("additionalReps", e.target.value)}
        error={
          Boolean(
            formik.touched.additionalReps && formik.errors.additionalReps
          ) && formik.errors.additionalReps
        }
        row="4"
        {...formik.getFieldProps("additionalReps")}
      />

      <Input
        label="Agency URL"
        name="artistUrl"
        onChange={formik.handleChange}
        value={formik.values.artistUrl}
        prefix="https://"
        error={
          Boolean(formik.touched.artistUrl && formik.errors.artistUrl) &&
          formik.errors.artistUrl
        }
        {...formik.getFieldProps("artistUrl")}
      />

      <Input
        label="Personal Website"
        name="personalWebsite"
        onChange={formik.handleChange}
        value={formik.values.personalWebsite}
        prefix="https://"
        error={
          Boolean(
            formik.touched.personalWebsite && formik.errors.personalWebsite
          ) && formik.errors.personalWebsite
        }
        {...formik.getFieldProps("personalWebsite")}
      />

      <Input
        label="Instagram"
        name="instagramUserName"
        onChange={formik.handleChange}
        value={formik.values.instagramUserName}
        prefix="@"
        error={
          Boolean(
            formik.touched.instagramUserName && formik.errors.instagramUserName
          ) && formik.errors.instagramUserName
        }
        {...formik.getFieldProps("instagramUserName")}
      />
    </div>
  );
};

export default EditArtistDetails;
